const sample = require('lodash.sample')
const flatten = require('lodash.flatten')
const isNumber = require('is-number')

const { randomInt, randomBool } = require('../util')
const lineHeight = require('./line-height')
const fontSizes = require('./font-sizes')

const googleFonts = require('../../data/fonts/google-fonts.json')

const googleFontPairs = require('../../data/fonts/google-font-pairs.json')
const typewolfFontPairs = require('../../data/fonts/typewolf-font-pairs.json')

const sansFonts = require('../../data/fonts/google-curated-sans.json')
const serifFonts = require('../../data/fonts/google-curated-serif.json')
const monoFonts = require('../../data/fonts/google-curated-mono.json')
const typewolfFonts = require('../../data/fonts/typewolf-fonts.json')

// We purposefully allow for duplicated fonts because it will increase
// their likelihood of being sampled. Fonts that appear frequently are
// more likely to look nice with others. We might want to introduce
// data/fonts/google-fonts so that all fonts are represented, but that
// might introduce a bit too much chaos. A lot of fonts are pretty bad.
const allFonts = [
  ...sansFonts,
  ...serifFonts,
  ...monoFonts,
  ...typewolfFonts,
  ...flatten(googleFontPairs),
  ...flatten(typewolfFontPairs)
]

// Use a weighting system to determine lists to query from. When we
// land on sans/serif/mono fonts we also randomize the order between
// heading and body for a bit more entropy. We select the other  from
// allFonts to allow for more possible outcomes.
const WEIGHTS = {
  googleFontPairs: 2,
  typewolfFontPairs: 5,
  sansFonts: 6,
  serifFonts: 7,
  monoFonts: 8
}

// Not all font weights are available for all fonts. We also don't
// care about italic variants right now so we filter those out.
const availableFontWeights = fontFamily => {
  const font = googleFonts.find(f => f.family === fontFamily)

  if (!font) {
    return []
  }

  return font.variants.filter(isNumber)
}

// Turn a google font name into a full font stack.
const fullFontFamily = fontFamily => {
  const font = googleFonts.find(f => f.family === fontFamily) || {}

  return [
    font.family ? fontFamily : 'system-ui',
    font.category === 'sans-serif' ? 'system-ui' : null,
    font.category || 'sans-serif'
  ]
    .filter(Boolean)
    .join(', ')
}

// Turn two google font names into a full CSS object.
const fullDefinition = pair => {
  const heading = {
    name: pair[0],
    fontFamily: fullFontFamily(pair[0]),
    fontWeight: sample(availableFontWeights(pair[0])) || 'normal',
    lineHeight: randomInt(120, 160) / 100,
    availableFontWeights: availableFontWeights(pair[0])
  }

  const bodyFontWeight = sample(availableFontWeights(pair[1]))
  const body = {
    name: pair[1],
    fontFamily: fullFontFamily(pair[1]),
    fontWeight: bodyFontWeight < 600 ? bodyFontWeight : 400,
    lineHeight: randomInt(140, 200) / 100,
    availableFontWeights: availableFontWeights(pair[1])
  }

  return {
    heading,
    body,
    fontSizes: fontSizes()
  }
}

const getPair = () => {
  const randInt = randomInt(0, 12)

  if (randInt <= WEIGHTS.googleFontPairs) {
    return sample(googleFontPairs)
  } else if (randInt <= WEIGHTS.typewolfFontPairs) {
    return sample(typewolfFontPairs)
  } else if (randInt <= WEIGHTS.sansFonts) {
    const pair = [sample(sansFonts), sample(allFonts)]

    return randomBool() ? pair : pair.reverse()
  } else if (randInt <= WEIGHTS.serifFonts) {
    const pair = [sample(serifFonts), sample(allFonts)]

    return randomBool() ? pair : pair.reverse()
  } else if (randInt <= WEIGHTS.monoFonts) {
    const pair = [sample(monoFonts), sample(allFonts)]

    return randomBool() ? pair : pair.reverse()
  }

  return [sample(allFonts), sample(allFonts)]
}

module.exports = () => fullDefinition(getPair())
