import React, { useState, useEffect } from 'react'
import { ArrowRight, Heart } from 'react-feather'

import Input from '../elements/Input'
import Textarea from '../elements/Textarea'
import Span from '../elements/Span'
import Div from '../elements/Div'
import H4 from '../elements/H4'
import P from '../elements/P'
import Flex from '../components/Flex'
import Lock from '../components/Lock'
import Layout from '../components/VoteLayout'
import Label from '../components/Label'
import SectionBorder from '../components/SectionBorder'
import Hidden from '../components/hidden'

import VoteButton from '../components/vote-button'
import SidebarHeading from '../components/heading-sidebar'

import randomGoogleFontPairing from '../lib/generate/google-font-pairing'
import { db } from '../lib/client'
import { flatten, toGoogleFontUrl } from '../lib/util'
import { UP_KEYCODE, RIGHT_KEYCODE } from '../lib/constants'

const ALPHABET = `
abcdefghijklmnopqrstuvwxyz
`
const CHARS = `
1234567890-~!@#$%^&*()_
`

const DEFAULT_TITLE_CONTENT = `
Everyone Is Genuinely Confused About Why This Heading Is Making People Freak Out
`

const DEFAULT_BODY_CONTENT = `
Perhaps the hardest part of breaking down the font selection process is
understanding which parts are more subjective and which parts are more
objective. After reading and digesting your client’s text, it is easier to
start with the objective aspects of typeface selection because they — by
default — make subjective decisions for us.

Augue eget arcu dictum varius. Dignissim convallis aenean et tortor at
risus viverra adipiscing at. Est ante in nibh mauris cursus mattis
molestie. Condimentum mattis pellentesque id nibh tortor id.

Quam lacus suspendisse faucibus interdum posuere lorem ipsum dolor sit.
Vehicula ipsum a arcu cursus vitae congue mauris rhoncus aenean. In arcu
cursus euismod quis. In pellentesque massa placerat duis ultricies lacus
sed.
`

const googleFontPairingToTheme = pairing => {
  return {
    fonts: {
      body: pairing.body.fontFamily,
      heading: pairing.heading.fontFamily
    },
    lineHeights: {
      body: pairing.body.lineHeight,
      heading: pairing.heading.lineHeight
    },
    fontWeights: {
      body: pairing.body.fontWeight,
      heading: pairing.heading.fontWeight
    }
  }
}

const randomFontPairing = () => {
  const googleFonts = randomGoogleFontPairing()

  console.log(googleFonts)

  return {
    ...googleFontPairingToTheme(googleFonts),
    metadata: {
      fontLinkHref: toGoogleFontUrl(googleFonts)
    }
  }
}

const Main = ({ fontPairing, title, body }) => (
  <Div>
    <link rel="stylesheet" href={fontPairing['metadata.fontLinkHref']} />
    <Flex
      pt={6}
      alignItems="flex-start"
      flexWrap="wrap"
      justifyContent={['center']}
    >
      <Flex
        fontSize={[2, 2, 3]}
        flexWrap="wrap"
        width={[1, 1, 'auto']}
        mx={['auto', 'auto', 0]}
        mb={5}
        maxWidth={['100%', '20em', '20em']}
        justifyContent="center"
      >
        <Div px={[3, 4]}>
          <Flex fontSize={0} alignItems="flex-start">
            <H4 mt={0}>{fontPairing['fonts.heading']}</H4>
            <Span pl={3}>{fontPairing['fontWeights.heading']}</Span>
          </Flex>
          <Div fontFamily={fontPairing['fonts.heading']}>
            <P my={0} style={{ textTransform: 'uppercase' }}>
              {ALPHABET}
            </P>
            <P my={0}>{ALPHABET}</P>
            <P my={0}>{CHARS}</P>
          </Div>
        </Div>
        <Div px={[3, 4]} pt={3}>
          <Flex fontSize={0}>
            <H4>{fontPairing['fonts.body']}</H4>
            <Span pl={3}>{fontPairing['fontWeights.body']}</Span>
          </Flex>
          <Div fontFamily={fontPairing['fonts.body']}>
            <P my={0} style={{ textTransform: 'uppercase' }}>
              {ALPHABET}
            </P>
            <P my={0}>{ALPHABET}</P>
            <P my={0}>{CHARS}</P>
          </Div>
        </Div>
      </Flex>
      <Div mx="auto" maxWidth="40em" px={[3, 4, 5]} fontSize={18}>
        <h2
          css={{
            marginTop: 0,
            fontSize: 32,
            fontFamily: fontPairing['fonts.heading'],
            fontWeight: fontPairing['fontWeights.heading'],
            lineHeight: fontPairing['lineHeights.heading']
          }}
        >
          {title}
        </h2>
        {body.map((str, i) => (
          <P
            key={i}
            css={{
              fontSize: 18,
              fontFamily: fontPairing['fonts.body'],
              lineHeight: fontPairing['lineHeights.body']
            }}
          >
            {str}
          </P>
        ))}
      </Div>
    </Flex>
  </Div>
)

const Sidebar = ({
  onChange,
  onContentChange,
  content,
  value,
  pins,
  onPin
}) => {
  const changeValue = key => e => {
    onChange(key, e.target.value)
  }

  return (
    <>
      <SidebarHeading>Typefaces</SidebarHeading>
      <h5>Heading</h5>
      <Flex mr={-3} py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins['fonts.heading']}
          onClick={() => onPin('fonts.heading', value['fonts.heading'])}
        />
        <Label>Font</Label>
        <Input
          value={value['fonts.heading']}
          onChange={changeValue('fonts.heading')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins['lineHeights.heading']}
          onClick={() =>
            onPin('lineHeights.heading', value['lineHeights.heading'])
          }
        />
        <Label>Line height</Label>
        <Input
          min={0.8}
          max={2}
          step={0.05}
          width={1}
          mr={2}
          type="range"
          value={value['lineHeights.heading']}
          onChange={changeValue('lineHeights.heading')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value['lineHeights.heading']}
        />
      </Flex>
      <Div py={1}>
        <Label mb={1}>Content</Label>
        <Textarea
          p={2}
          width={1}
          height={48}
          border="1px solid rgba(0,0,0,.25)"
          onChange={e => onContentChange('title', e.target.value)}
        >
          {content.title}
        </Textarea>
      </Div>
      <SectionBorder mt={3} />
      <h5>Text</h5>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins['fonts.body']}
          onClick={() => onPin('fonts.body', value['fonts.body'])}
        />
        <Label>Font</Label>
        <Input
          value={value['fonts.body']}
          onChange={changeValue('fonts.body')}
        />
      </Flex>
      <Flex py={1} alignItems="center">
        <Lock
          bg="transparent"
          active={pins['lineHeights.body']}
          onClick={() => onPin('lineHeights.body', value['lineHeights.body'])}
        />
        <Label>Line height</Label>
        <Input
          min={1}
          max={2}
          step={0.05}
          width={1}
          mr={2}
          type="range"
          value={value['lineHeights.body']}
          onChange={changeValue('lineHeights.body')}
        />
        <Span
          width="80px"
          textAlign="right"
          ml="auto"
          fontSize={0}
          children={value['lineHeights.body']}
        />
      </Flex>
      <Div py={1}>
        <Label mb={1}>Content</Label>
        <Textarea
          p={2}
          height={96}
          width={1}
          border="1px solid rgba(0,0,0,.25)"
          onChange={e => onContentChange('body', e.target.value)}
        >
          {content.body}
        </Textarea>
      </Div>
    </>
  )
}

export default () => {
  const [fontPairing, setFontPairing] = useState(randomFontPairing())
  const [pins, setPins] = useState({})
  const [content, setContent] = useState({
    title: DEFAULT_TITLE_CONTENT.trim(),
    body: DEFAULT_BODY_CONTENT.trim()
  })

  const flattenedFontPairing = flatten(fontPairing)
  const fullFontPairing = flatten({ ...flattenedFontPairing, ...pins })

  const upvote = () => {
    db.collection('components')
      .doc('fontPairings')
      .collection('votes')
      .add({
        up: true,
        data: fullFontPairing,
        metadata: { pins }
      })

    setFontPairing(randomFontPairing())
  }
  const skip = () => {
    setFontPairing(randomFontPairing())
  }

  const handleVoteKey = e => {
    if (e.keyCode === UP_KEYCODE || e.keyCode === RIGHT_KEYCODE) {
      e.preventDefault && e.preventDefault()
    } else {
      return
    }

    if (e.keyCode === UP_KEYCODE) {
      upvote()
    } else if (e.keyCode === RIGHT_KEYCODE) {
      skip()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleVoteKey)

    return () => window.removeEventListener('keydown', handleVoteKey)
  })

  return (
    <Layout
      main={
        <>
          <Main
            fontPairing={fullFontPairing}
            title={content.title.trim()}
            body={content.body.trim().split(/\n{2,}/g)}
          />
          <pre
            css={{
              marginTop: 160,
              borderTop: 'thin solid rgba(0,0,0,.1)',
              padding: 20,
              overflow: 'auto'
            }}
          >
            {JSON.stringify(fontPairing, null, 2)}
          </pre>
        </>
      }
      sidebar={
        <Sidebar
          value={fullFontPairing}
          pins={pins}
          content={content}
          onContentChange={(key, value) => {
            setContent({
              ...content,
              [key]: value
            })
          }}
          onChange={(key, value) => {
            setFontPairing({
              ...fontPairing,
              [key]: value
            })

            setPins({
              ...pins,
              [key]: value
            })
          }}
          onPin={(key, value) => {
            if (!pins[key]) {
              setPins({
                ...pins,
                [key]: value
              })
            } else {
              const newPins = { ...pins }
              delete newPins[key]
              setPins(newPins)
            }
          }}
        />
      }
      footer={
        <>
          <Flex>
            <VoteButton onClick={upvote}>
              <Heart size={20} />
              <Hidden>Save</Hidden>
            </VoteButton>
            <VoteButton onClick={skip}>
              <ArrowRight size={20} />
              <Hidden>Next</Hidden>
            </VoteButton>
          </Flex>
        </>
      }
    />
  )
}
